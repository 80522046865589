.button {
    padding: 20px 26px 26px;
}

.slide {
    position: absolute;
    overflow-x: hidden;
}

.container {
    overflow-x: hidden;
}
