.titleContainer {
    margin-bottom: 15px;
}

.title {
    text-align: center;
}

.batChatContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
