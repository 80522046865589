.questionOptionLight, .questionOptionTouchedLight, .questionOptionUntouchedLight, .questionOptionDark, .questionOptionTouchedDark, .questionOptionUntouchedDark {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 20px;
    margin-left: 5px;
    cursor: pointer;
}

.questionOptionDark {
    background-color: #0e0e0e;
}

.questionOptionTouchedDark {
    background-color: #237EDD;
}

.questionOptionLight {
    background-color: #c7c9ce;
}

.questionOptionTouchedLight {
    background-color: #0077ff;
}


.questionOptionTouchedLight, .questionOptionTouchedDark {
    width: 46px;
    height: 46px;
    border-radius: 23px;
}

.block {
    margin: 0
}

.buttonContainer {
    display: flex;
    justify-content: center;
    margin-bottom: 10%;
    margin-top: 5%;
}

.button {
    width: 80%;
}

.statementContainer {
    padding: 4% 2%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.optionContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
}
